import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import {  SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import assetsModule from '@/store/modules/assetsModule';
import workflowModule from '@/store/modules/workflowModule';;
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import metaDataModule from '@/store/modules/metaDataModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { isGoodService } from './helpers';
import userPreferenceDB from '@/lib/userPreference';

async function preloadData(vueInstance) {
  const isOnlineApp = await isGoodService();

  if (isOnlineApp && !tasqsListModule.savingForOffline) {
      tasqsListModule.setsaveOffline(true);
      vueInstance.$router.push({ name: 'FormMobileSubmission' });
      await sleep(3000);  // if this delay is necessary, keep it, otherwise consider reducing/removing

      if (!accountModule.user || !accountModule.user.email) {
          return;
      }

      if (!accountModule.didPreloadData) {
          await preloadAllData(vueInstance);
      }
  }
}

async function preloadAllData(vueInstance) {

  await userPreferenceDB.clear()
  const promises = [
      preloadingActions(vueInstance),
      tasqsListModule.getSubmittedForms(),
      tasqsListModule.getWaitList(),
  ];

  try {
      await Promise.all(promises);
      accountModule.setDidPreloadData(true);
      // vueInstance.$router.push({ name: 'UserProfile' });
      setTimeout(() => {
          vueInstance.$router.push({ name: 'Tasqs' });
          tasqsListModule.setsaveOffline(false);
      }, 1500);
  } catch (err) {
      console.error(err);
      vueInstance.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
          text: 'It is not possible to preload data at this moment, please try later',
          error: true
      });
      tasqsListModule.setsaveOffline(false);
  }
}


async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// async function preloadingTasqCharts(result) {
//   try {
//     // console.log(result.engineerType)
//     if (result.engineerType === 'Setpoint') {
//       // await setpointV2Module.getSetpointRecV2ForTasq({ wellName: result.wellName, isOfflinePreloading: true });
//     }
//     await tasqSignalsModule.getSignalsForTasq({ daysLength: 5, wellName: result.wellName });
//     await (tasqProductionDataChartModule.getProductionData({ daysLength: 5, wellName: result.wellName }));
//     // await (defermentLabelingModule.getChartLabelingData({signalChartTime: 5, wellName: result.wellName}));
//   } catch (error) {
//     console.log(`Something went wrong on signals offline storage ${result.wellName}`);
//   }
// }



// async function setWaitingOnOptions() {
//   await metaDataModule.getDatalist();
//   const requiredDataList = metaDataModule.requiredDatalist;
//   const formCategoriesItem = requiredDataList.find((list) => list.title === 'Waiting On');
//   // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
//   if (formCategoriesItem) {
//     const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
//     if (formCategoriesPayload && formCategoriesPayload.length) {
//       const waitingOnOptions = formCategoriesPayload[0].items.filter((field) => field.active).map((item) => item.Name).sort();
//       if (waitingOnOptions) {
//         metaDataModule.setWaitingOnList(waitingOnOptions);
//       }
//     }
//   }
// }

async function preloadingActions(vueThis) {
  try {
    // console.error('I m preloading');
    // await (workflowModule.getJobResponse({ id: result.id }));
    const actions: any = await metaDataModule.getActionsBuilder();


  } catch (error) {
    console.log('Something went wrong on Action offline storage');
  }
}

export {
  preloadData,
};
