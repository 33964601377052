var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('main',{staticClass:"min-h-0 bg-scroll flex-grow",attrs:{"id":"app_layout"}},[_c('section',{staticClass:"grid h-full"},[(!_vm.isMobile)?_c('app-sidebar',{ref:"sidebar",style:(!_vm.sidebarCondition ? `zoom: ${_vm.zoomScale}` : '')}):_vm._e(),(_vm.username)?_c('section',{staticClass:"flex flex-col"},[(_vm.isMobile && !_vm.isOnlineApp)?_c('p',{staticClass:"text-gray001 cursor-pointer text-center text-2xl bg-blue034"},[_c('span',{staticClass:"material-icons align-middle mr-2"},[_vm._v(" cloud_off ")]),_c('span',{staticClass:"text-base align-middle"},[_vm._v("Offline Mode")])]):_vm._e(),_vm._t("header",function(){return [_c('app-header')]}),(_vm.isNotificationListOpen)?_c('notification-list'):_vm._e(),_c('div',{staticClass:"mb-0 md:mb-px min-h-0 flex-grow"},[_vm._t("default")],2)],2):_vm._e(),_c('app-footer',{ref:"footer"})],1),(
      _vm.showEndpointResponseMsg &&
        _vm.endpointResponseMsg &&
        _vm.endpointResponseMsg.length
    )?_c('div',{staticClass:"fixed ml-5 top-0 m-auto w-60 py-3 float-right mt-5 shadow-lg",class:_vm.isErrorInResponseMsg ? 'failed_notification' : 'saved_notification'},[_vm._m(0),_c('div',{staticClass:"float-right h-full",staticStyle:{"width":"calc(100% - 50px)"}},[_c('p',{staticClass:"text-sm text-white font-normal mt-1"},[_vm._v(" "+_vm._s(_vm.endpointResponseMsg)+" ")])])]):_vm._e(),_c('t-alert',{staticClass:"md:bottom-3 bottom-28 right-1 md:right-3",attrs:{"show":_vm.showAlert},on:{"hidden":function($event){_vm.showAlert = false}}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),(_vm.isPreLoadingForOffline)?_c('div',{staticClass:"overflow-x-hidden w-full overflow-y-auto fixed inset-0 outline-none focus:outline-none justify-center items-center flex z-50"},[_c('div',{staticClass:"relative w-full lg:w-auto back-shadow bg-blue030 h-screen"},[_c('div',{staticClass:"ml-0 mt-0 w-full h-full relative"},[_c('tasq-loader',{staticClass:"app-loader-charts"}),_c('p',{staticClass:"mt-0 text-center w-full align-middle text-lg font-medium text-white py-20 px-10"},[_vm._v(" Please wait, while we are preparing the app for you ... ")])],1)])]):_vm._e(),(
      _vm.currentAppLevelGptNotification && _vm.currentAppLevelGptNotification.message
      && _vm.showAppLevelGptNotification
    )?_c('GptSuccessBanner',{attrs:{"message":_vm.currentAppLevelGptNotification.message,"duration":_vm.currentAppLevelGptNotification.duration,"title":_vm.currentAppLevelGptNotification.title},on:{"close":function($event){_vm.currentAppLevelGptNotification = null}}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"float-left h-full",staticStyle:{"width":"50px"}},[_c('span',{staticClass:"material-icons float-left text-white pl-2 opacity-70 text-3xl"},[_vm._v("task_alt")])])
}]

export { render, staticRenderFns }