
import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import accountModule from '@/store/modules/accountModule';
import { getComponent, isGoodService,sleep } from '@/utils/helpers';
import { SHOW_ALERT, SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import workflowModule from '@/store/modules/workflowModule';
import { preloadData } from '@/utils/preloadOfflineData';
import metaDataModule from '@/store/modules/metaDataModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import { Debounce } from 'vue-debounce-decorator';
import { mixins } from 'vue-class-component';
import userPreferenceDB from '@/lib/userPreference';
import gptModule from '@/store/modules/gptModule';
import * as Sentry from '@sentry/vue';
import GenericMixin from '../../lib/mixins/GenericMixin';

@Component({
  components: {
    NotificationList: () => getComponent('notifications/NotificationList'),
    AppSidebar: () => getComponent('common/AppSidebar'),
    AppFooter: () => getComponent('common/AppFooter'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    AppHeader: () => getComponent('common/TheHeader'),
    GptSuccessBanner: () => getComponent('gpt/GptSuccessBanner'),
  },
})
export default class AppLayout extends mixins(GenericMixin) {
  notificationsOpen = false;

  open = false;

  right = false;

  zoomScale = 1;

  showAlert = false;

  intervalId: any = null;

  isOnlineApp = navigator.onLine;

  windowWidth = window.innerWidth;

  alertText = '';

  showEndpointResponseMsg = false;

  isErrorInResponseMsg = false;

  endpointResponseMsg = '';

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async beforeMount() {
    if (window.location.pathname === '/') {
      if (
        workflowModule.user.defaultPage
        && workflowModule.user.defaultPage.includes('kanban')
      ) {
        this.$router.push({ name: 'TasqsKanban' });
      } else {
        this.$router.push({ name: 'Tasqs' });
      }
    }

    await accountModule.getReassignmentList({ useExisting: true });
    // await workflowModule.getUserNotifications();
  }

  @Debounce(1000)
  async onResize() {
    this.windowWidth = window.innerWidth;
    await this.prepareViewPort();
  }

  get username() {
    return workflowModule.user.username;
  }

  get isMobile() {
    return this.windowWidth < 768;
  }

  get isPreLoadingForOffline() {
    return tasqsListModule.savingForOffline;
  }

  get isNotificationListOpen() {
    return tasqsListModule.isNotificationListOpen;
  }

  async prepareViewPort() {
    const mainBody: any = document.getElementsByTagName('body')!;
    const zoom = 1;
    if (mainBody && mainBody[0]) {
      const mainBodyHeight = mainBody[0].clientHeight;
      if (this.sidebarCondition) {
        accountModule.setViewPortClientHeight(mainBodyHeight);
      }
      accountModule.setViewPortZoom(zoom);
      const chatWidget: any = document.getElementById('chat-widget-container')!;
      if (chatWidget) {
        chatWidget.style.zoom = `${this.canvasZoomLevel}`;
      }
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('online', this.onlineMode);
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('offline', this.offlineMode);
    // if (this.intervalId) {
    //   clearInterval(this.intervalId);
    // }
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      // if (this.isMobile) {
      //   this.intervalId = setInterval(this.monitorConnectionStatus, 15000);
      // }
    });
  }

  async created() {
    window.addEventListener('online', this.onlineMode);
    window.addEventListener('offline', this.offlineMode);
    this.monitorConnectionStatus();
    this.windowWidth = window.innerWidth;
    if (this.isOnlineApp && this.isMobile) {
      await this.postPendingPromises();
      if (!accountModule.didPreloadData) {
        await preloadData(this);
      }
    }
    this.$eventBus.$on(SHOW_ALERT, (text) => {
      this.showEndpointResponseMsg = false;
      this.showAlert = true;
      this.alertText = text;
      this.timeAlert(4000);
    });

    this.$eventBus.$on(SHOW_ENDPOINT_RESPONSE_ALERT, (payload) => {
      this.showAlert = false;
      this.showEndpointResponseMsg = true;
      this.endpointResponseMsg = payload.text;
      this.isErrorInResponseMsg = payload.error;
      this.timmerForEndpointResponse(8000);
    });

    if (!metaDataModule.sentryInitialize) {
    Sentry.setUser({ email: accountModule.user.email });
      metaDataModule.setSentryState(true);
    }
  }

  async onlineMode() {
    this.monitorConnectionStatus();
    if (this.isOnlineApp && this.isMobile) {
      await this.postPendingPromises();
    }
  }

  async offlineMode() {
    await this.monitorConnectionStatus();
  }

  async monitorConnectionStatus() {
    const preIsOnline = this.isOnlineApp;
    this.isOnlineApp = await isGoodService();
    if (this.isOnlineApp !== preIsOnline) {
      await this.postPendingPromises();
    }
  }

  @Debounce(3000)
  async postPendingPromises() {
    if (!this.isOnlineApp) {
      return;
    }

    console.log('post pending ');

    let pendingTasqsPromise: any = await userPreferenceDB.getItem('offline-pending-tasqs');
      await userPreferenceDB.setItem('offline-pending-tasqs', JSON.stringify([]));
    await window.localStorage.setItem(
      'offline-pending-tasqs',
      JSON.stringify([]),
    );
    if (pendingTasqsPromise && JSON.parse(pendingTasqsPromise)) {
      pendingTasqsPromise = JSON.parse(pendingTasqsPromise);
      if (pendingTasqsPromise && pendingTasqsPromise.length) {
        const bulkPromises = [];

        pendingTasqsPromise.forEach((pendingRequest) => {
          if (pendingRequest.type === 'addTasq') {
            bulkPromises.push(
              tasqActionsModule.addTasq({ ...pendingRequest.input }),
            );
          } else if (pendingRequest.type === 'postJobFormsResponse') {
            bulkPromises.push(
              metaDataModule.postJobFormsResponse(pendingRequest.input),
            );
          }
        });

        const failedPromises = [];

        Promise.allSettled(bulkPromises).then(async (promiseResult) => {
          promiseResult.forEach((result, index) => {
            console.log(result.status);
            if (result.status === 'rejected') {
              failedPromises.push(pendingTasqsPromise[index]);
            }
          });

          if (failedPromises.length) {
            this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
              text: 'Pending Forms Submission Failed',
              error: true,
            });
          } else {
            this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
              text: 'Pending Forms Submitted',
              error: false,
            });
            tasqsListModule.setPendingTasqPromise([]);
            await tasqsListModule.getSubmittedForms();
          }

          console.log(failedPromises);
          tasqsListModule.setPendingTasq(failedPromises);
          await userPreferenceDB.setItem('offline-pending-tasqs', JSON.stringify(failedPromises));
          await window.localStorage.setItem(
            'offline-pending-tasqs',
            JSON.stringify(failedPromises),
          );

          // tasqsListModule.setPendingTasq([]);
          // tasqsListModule.setPendingTasqPromise([]);
          // await tasqsListModule.getSubmittedForms();
          // await window.localStorage.setItem('offline-pending-tasqs', JSON.stringify([]));
        });
      }
    }
  }

  getPromiseState(promise) {
    // We put `pending` promise after the promise to test,
    // which forces .race to test `promise` first
    return Promise.race([promise]).then(
      (value) => ({
        state: 'resolved',
        value,
      }),
      (reason) => ({ state: 'rejected', reason }),
    );
  }

  async timeAlert(time) {
    await this.sleep(time);
    this.showAlert = false;
    this.alertText = '';
  }

  async timmerForEndpointResponse(time) {
    await this.sleep(time);
    this.showEndpointResponseMsg = false;
    this.isErrorInResponseMsg = false;
    this.endpointResponseMsg = '';
  }

  get appLevelGptNotifications() {
    return gptModule.appLevelNotification;
  }

  currentAppLevelGptNotification: any = null;

  showAppLevelGptNotification =  false

  @Watch('appLevelGptNotifications')
  async onUpdateAppLevelGptNotifications(value) {
    if (value) {
      this.showAppLevelGptNotification = false;
      this.$nextTick(() => {
        this.showAppLevelGptNotification = true;
        this.currentAppLevelGptNotification = value;
      });
      await sleep(4000);
      gptModule.setNotfications(null);
    }
  }
}
